import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './home_components/TopBar';
import MobileMenu from './home_components/MobileMenu';
import StudyBar from './home_components/StudyBar';
import SectionSelector from './home_components/SectionSelector';
import CategoryFilter from './home_components/CategoryFilter';
import ExamList from './home_components/ExamList';
import LockedExamModal from '../general_components/LockedExamModal';
import { BeatLoader } from 'react-spinners';
import { SECTIONS, LEVELS, STRIPE_PUBLIC_KEY } from '../constants';
import { getUserProfile } from '../api/auth';
import { fetchUserExams, initiateCheckoutSession } from '../api/exams';
import { extractCategories } from './home_components/formatters';
import mixpanel from '../mixpanel';

function Home() {
  const navigate = useNavigate();
  const sections = useMemo(() => SECTIONS, []);

  // State declarations
  const [activeSection, setActiveSection] = useState(sections[0].name);
  const [selectedLevel, setSelectedLevel] = useState(() => {
    return localStorage.getItem('selectedLevel') || "N3";
  });
  const [sectionData, setSectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showLockedExamModal, setShowLockedExamModal] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);
  const [isMobileSectionDropdownOpen, setIsMobileSectionDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const handleLevelChange = (newLevel) => {
    setSelectedLevel(newLevel);
    localStorage.setItem('selectedLevel', newLevel);
  };

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleInitiateCheckout = async () => {
    mixpanel.track('Get full access', {});
    try {
      const data = await initiateCheckoutSession();
      if (data.sessionId) {
        const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      }
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    navigate('/login');
  };

  const fetchData = useCallback(async () => {
    const section = sections.find(s => s.name === activeSection);
    if (!section) return;

    try {
      setLoading(true);
      setError(null);

      const [examsData, userData] = await Promise.all([
        fetchUserExams(selectedLevel, section.apiSection),
        getUserProfile()
      ]);

      setSectionData(examsData);
      setCategories(extractCategories(examsData));
      
      if (userData) {
        setUserStatus(userData.status);
        setUserData(userData);
        mixpanel.identify(userData.user_id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [activeSection, selectedLevel, sections]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredExams = useMemo(() => {
    if (selectedCategories.length === 0) return sectionData;
    return sectionData.filter(exam => {
      if (!exam?.categories?.length) return false;
      return selectedCategories.every(category => exam.categories.includes(category));
    });
  }, [sectionData, selectedCategories]);

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="study-plan-container">
      <TopBar
        isMobileView={isMobileView}
        userStatus={userStatus}
        userData={userData}
        onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        onInitiateCheckout={handleInitiateCheckout}
      />

      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        userStatus={userStatus}
        onInitiateCheckout={handleInitiateCheckout}
        onLogout={handleLogout}
      />

      <StudyBar
        selectedLevel={selectedLevel}
        levels={LEVELS}
        onLevelChange={handleLevelChange}
      />

      <SectionSelector
        sections={sections}
        activeSection={activeSection}
        isDropdownOpen={isMobileView ? isMobileSectionDropdownOpen : isSectionDropdownOpen}
        onSectionChange={(section) => {
          setActiveSection(section);
          setIsMobileSectionDropdownOpen(false);
          setIsSectionDropdownOpen(false);
        }}
        onDropdownToggle={() => {
          if (isMobileView) {
            setIsMobileSectionDropdownOpen(!isMobileSectionDropdownOpen);
          } else {
            setIsSectionDropdownOpen(!isSectionDropdownOpen);
          }
        }}
        isMobileView={isMobileView}
      />

      <CategoryFilter
        categories={categories}
        selectedCategories={selectedCategories}
        onToggleCategory={toggleCategory}
      />

      <ExamList exams={filteredExams} />

      {showLockedExamModal && (
        <LockedExamModal
          onClose={() => setShowLockedExamModal(false)}
          onGetFullAccess={() => {
            setShowLockedExamModal(false);
            handleInitiateCheckout();
          }}
        />
      )}
    </div>
  );
}

export default Home;